import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string,
};

function SectionHeader({ headerText, subHeaderText }) {
  return (
    <div className="section-header-container">
      <h2 className="text-lg header">{headerText}</h2>
      <h3 className="sub-header">{subHeaderText}</h3>
    </div>
  );
}

SectionHeader.propTypes = propTypes;

export default SectionHeader;
